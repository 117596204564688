import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Image from "../components/Image"
import Form from "../components/Form"

const HeroCustom = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "PyramidAnatomyLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="section" id="form">
      <div className="container">
        {/* <div className="columns is-centered px-6">
          <div className="column is-one-fifth is-flex is-align-items-center is-justify-content-flex-start">
            <Image data={data} className="w-100 max-width-250" />
          </div>
          <div className="column is-flex is-align-items-center">
            <h2 className="title is-size-3 has-text-weight-semibold">
              Apprends/
              <br />
              Valides tes connaissances/
              <br />
              Révises/
              <br />
              Valides tes exams!
            </h2>
          </div>
          <div className="column has-text-right is-flex is-justify-content-flex-end is-align-items-center">
            <div className="stamps subtitle is-size-6 has-text-centered has-text-weight-semibold">
              La méthode la plus facile et rapide pour apprendre tes cours
              d’anatomie par coeur.
            </div>
          </div>
        </div> */}
        <div className="is-flex is-flex-wrap-wrap is-justify-content-space-between px-6">
          <div className="is-flex is-align-items-center is-justify-content-flex-start mb-6">
            <div className="is-flex is-flex-wrap-wrap is-align-items-center">
              <div className="mr-6">
                <Image
                  data={data}
                  className="w-100 max-width-250 min-width-175"
                />
              </div>
              <h2 className="title is-size-4 has-text-weight-semibold">
                Apprends/
                <br />
                Valides tes connaissances/
                <br />
                Révises/
                <br />
                Valides tes exams!
              </h2>
            </div>
          </div>
          <div className="has-text-right is-flex is-justify-content-flex-end is-align-items-center mb-6">
            <div className="stamps max-width-250 min-width-200 subtitle is-size-6 has-text-centered has-text-weight-semibold">
              La méthode la plus facile et rapide pour apprendre tes cours
              d’anatomie par coeur.
            </div>
          </div>
        </div>
        <div className="columns mt-6 is-desktop">
          <div className="column is-two-thirds-desktop is-flex is-flex-direction-column is-justify-content-center px-6 mb-6">
            <h1 className="title is-size-1 has-text-weight-semibold">
              Deviens un crack en anatomie
            </h1>
            <h2 className="subtitle is-size-3 has-text-weight-light mt-3">
              Augmente ta productivité et maîtrise l’art d’apprendre par coeur
              en t’amusant pour enfin connaître ton anatomie sur le bout des
              doigts et arriver serein le jour de tes exams
            </h2>
          </div>
          <div className="column px-6">
            <div className="card has-boxshadow">
              <Form
                url="https://form.typeform.com/to/WjJSXqba?typeform-medium=embed-snippet"
                transparency="0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroCustom
