import React from "react"
import PyramidAnatomyLogoWithoutBG from "../images/LogoBlanc.png"
import LogoIg from "../images/LogoIg.png"

const Footer = () => {
  return (
    <div className="section has-background-black">
      <div className="container is-flex is-justify-content-center is-flex-direction-column is-align-items-center">
        <img
          src={PyramidAnatomyLogoWithoutBG}
          className="max-width-150"
          alt="Logo Pyramid"
        />
        <p className="is-italic has-text-white">
          “Les détails font la perfection, et la perfection n’est pas un
          détail.”
          <br />
          <span className="is-pulled-right has-text-white">L. De Vinci</span>
        </p>
        <a
          href="https://www.instagram.com/pyramidanatomy/"
          target="_blank"
          className="mt-6"
        >
          <img
            src={LogoIg}
            className="max-width-50"
            alt="Logo Instagram"
            rel="noreferrer"
          />
        </a>
        <a
          href="https://pretotype.fr"
          className="has-text-primary mt-6"
          target="_blank"
          rel="noreferrer"
        >
          made by pretotype.fr
        </a>
      </div>
    </div>
  )
}

export default Footer
