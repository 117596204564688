import React from "react"
import { Link } from "gatsby"

const CtaCentered = ({ label }) => {
  return (
    <div className="section">
      <div className="container">
        <div className="is-flex is-justify-content-center">
          <Link to="#form" className="stamps has-text-weight-bold">
            {label}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CtaCentered
