import React from "react"

const TextImageVertical = ({ subtitle, paragraph, image }) => (
  <div>
    {subtitle && <h2 className="title mb-6">{subtitle}</h2>}
    {paragraph && (
      <p className="subtitle mb-6 has-text-weight-semibold">
        {paragraph.split("\n").map(str => (
          <>
            {str}
            <br />
          </>
        ))}
      </p>
    )}
    {image && (
      <div className="my-6">
        <img src={image} alt={subtitle} />
      </div>
    )}
  </div>
)

export default TextImageVertical
