import React from "react"

import Layout from "../components/layout"
import HeroCustom from "../components/HeroCustom"
import SEO from "../components/seo"
import Footer from "../components/Footer"
import TwoColumns from "../components/TwoColumns"
import CtaCentered from "../components/CtaCentered"
import Presentation from "../components/Presentation"
import TextImageVertical from "../components/TextImageVertical"
import ImageTextVertical from "../components/ImageTextVertical"
import "../styles/styles.scss"

import craneFace from "../images/CraneFace.png"
import sphenoideFos from "../images/SphenoideFOS.png"
import capitatumAnt from "../images/CapitatumAnt.png"
import osCoxalLat from "../images/OsCoxalLat.png"
import aCarotideExterne from "../images/ACarotideExterne.png"
import samAnt from "../images/SamAnt.png"
import bulbeNCraniens from "../images/BulbeNCraniens.png"

const IndexPage = () => {
  const paragraph1 =
    "Grâce à Pyramid Anatomy retenir son cours n’a jamais été aussi simple, tu ne t’appercevras même pas que tu es en train d’apprendre tant notre méthode est ludique et sans faille."
  const paragraph2 =
    "Peut importe ton cursus, tu y trouveras toutes les informations dont tu as besoin, notre méthode est organisée de manière à ce que tu puisses apprendre par étape, du général au particulier selon tes besoins."
  const paragraph3 =
    "Profites de notre méthode et construit ta pyramide mentale à l’aide de notre plateforme d’entrainement faite de questionnaires interactifs qui t’aideront à répéter tes gammes."
  const paragraph4 =
    "Apprendre l’anatomie sans méthode est une pratique longue et laborieuse tant les informations sont nombreuses et ne laisse pas de place à l’à peu près. Mais ne vous en faites pas, la méthode nous l’avons et bonne nouvelle, vous n’êtes qu’à quelques clics de pouvoir l’essayer!"
  const paragraph5 = `- Tu cherche un moyen simple pour apprendre et réviser tes cours.
    \n- Tu passes des heures à dessiner ou réécrire 100 fois les mêmes choses et tu perds un temps fou à apprendre l’anatomie en dépit des autres matières de ton cursus.
    \n- Tu es submergé d’informations et tu ne sais plus par où commencer.
    \n- Tu oublies  au moins 40% des informations que tu apprends.
    \n- Tu as l’impression de perdre ton temps.
    \n- Tu n’as aucune logique pour apprendre l’anatomie.
    \n- Tu es perdu dans tes révisions.
    \n- Tu doutes de toi et tu stresses avant tes exams car tu ne te sens pas prêt?`
  const paragraph6 = `Une méthode d’apprentissage baptisée Pyramide qui va développer ta logique dans laquelle tu trouveras tout ça:
    \n- Une multitude de Pyramides organisés par catégories et niveaux d’acquisition de connaissances.
    \n- Une infinité de schémas explicites (précis, ludique, compréhensible, interactifs, inédits).
    \n- Des questions et des réponses sur à peu près tout, c’est simple chaque muscles/ os/ articulation… ,sera décortiqué dans les moindres détails afin de vous préparer à toutes éventuelles questions d’examen.
    \n- Des conseils d’apprentissage adaptés aux structures qui peuvent vous paraître plus complexe à intégrer.
    \n- Des dizaines de vidéo/fiches résumant rapidement un sujet.
    \n- un véritable atlas d’anatomie interactif dans ta poche!
    \n- Une plateforme  d’entre-aide où nous pourrons répondre à tes questions grâce à nos Experts.`
  const paragraph7 = `- Tu vas gagner et optimiser ton temps  grâce à notre méthode.
    \n- Tu développera ta logique et tu vas vite te rendre compte qu’apprendre l’anatomie, ce n’est pas si difficile.
    \n- Finis les dessins et réécriture interminable.
    \n- Tu pourras consacrer plus de temps à tes autres cours ou à tes loisirs.
    \n- Tu vas enfin pouvoir arriver serein le jour de tes exams.
    \n- Grâce au développement de ta logique anatomique tu sera en mesure de retrouver par toi même une information qui t’aurais échappée.
    \n- Tu vas profiter d’un outil accesssible n’importe où, n’importe quand depuis ton téléphone.
    \n- Tu obtiendras l’information la plus parfaite issus d’un consensus des atlas d’anatomie les plus utilisés. (tu pourras même adapter le sujet en fonction de ton cours)
    \n- L’anatomie n’aura plus de secrets pour toi!`

  return (
    <Layout>
      <SEO />
      <HeroCustom />
      <TwoColumns
        left={
          <>
            <TextImageVertical
              subtitle="Apprendre par coeur, c’est pas ton truc?"
              paragraph={paragraph1}
              image={craneFace}
            />
            <TextImageVertical
              subtitle="Etudiants en medecine, staps, ostéopathie, chiropracteur, coach sportif, infirmier…"
              paragraph={paragraph2}
              image={osCoxalLat}
            />
          </>
        }
        right={
          <>
            <ImageTextVertical
              image={sphenoideFos}
              subtitle="Tu cherches un moyen rapide, simple et efficace pour apprendre et réviser tes cours d’anatomie?"
              paragraph={paragraph3}
            />
            <ImageTextVertical
              image={capitatumAnt}
              subtitle="Comment passer moins de temps à apprendre tout en étant plus efficace?"
              paragraph={paragraph4}
            />
          </>
        }
      />
      <CtaCentered label="Je veux tester gratuitement !" />
      <TwoColumns
        left={
          <>
            <TextImageVertical
              subtitle="Pourquoi nous rejoindre?"
              paragraph={paragraph5}
              image={samAnt}
            />
            <TextImageVertical
              subtitle="Notre contenu:"
              paragraph={paragraph6}
            />
          </>
        }
        right={
          <>
            <ImageTextVertical
              image={aCarotideExterne}
              subtitle="Ce que Pyramid Anatomy va t’apporter:"
              paragraph={paragraph7}
            />
            <ImageTextVertical image={bulbeNCraniens} />
          </>
        }
      />
      <Presentation />
      <CtaCentered label="Je m'inscris" />
      <Footer />
    </Layout>
  )
}

export default IndexPage
