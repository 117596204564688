import React from "react"

const TwoColumns = ({ left, right }) => {
  return (
    <div className="section">
      <div className="columns is-centered is-variable is-8-desktop">
        <div className="column is-two-fifths-desktop">{left}</div>
        <div className="column is-two-fifths-desktop">{right}</div>
      </div>
    </div>
  )
}

export default TwoColumns
