import React from "react"

const Presentation = () => (
  <div className="section">
    <div className="columns is-centered">
      <div className="column is-two-thirds">
        <h2 className="title">Pourquoi j’ai créé pyramid anatomy ?</h2>
        <p className="mt-6 is-italic">
          Je m’appelle Jordan,
          <br />
          je suis le fondateur de Pyramid Anatomy, ayant dans un premier temps
          arrété d’étudier à 16ans, j’ai repris le cours de mes études à 25 ans
          dans le but de devenir ostéopathe. N’ayant à cette époque aucune
          méthode de travail efficace pour apprendre par coeur j’ai cherché en
          vain l’outil idéal qui me permettrai de retenir efficacement et de
          réviser mes cours d’anatomie. Devant cet échec, j’ai du trouver moi
          même le moyen de « hacker » l’apprentissage de cette discipline pour
          retenir un maximum d’informations en un minimum de temps tout en
          produisant le moins d’effort possible.
          <br />
          <br />
          Cela a fonctionné, m’a permis de valider mon cursus sans échec, de
          consacrer plus de temps aux autres matières de mes études et par le
          biais de ma méthode, d’aider nombre de mes camarades. Aujourd’hui avec
          tous les outils technologique dont nous disposons et fort de notre
          expérience, nous retranscrivons avec mon équipe, une version encore
          meilleure de cette méthode à travers Pyramid Anatomy afin que vous
          puissiez tous profiter de ce qui à fait ma réussite et changé le cours
          de mes études.
          <br />
          <br />
          C’est une méthode avec laquelle vous vous amuserez et n’aurez pas
          l’impression de vous forcer à apprendre par coeur pour enfin retenir
          l’essentiel de vos cours! Alors n’attendez plus, inscrivez vous par
          email si vous désirez en savoir plus et essayer la méthode Pyramid
          Anatomy.
          <br />
          Profitez en, c’est gratuit!
          <br />
          <br />
          Jordan.
        </p>
      </div>
    </div>
  </div>
)

export default Presentation
